<template>
  <NuxtLink :to="getRouteToItem(item)" class="block">
    <div
      ref="el"
      class="relative h-full w-full rounded-lg bg-secondary shadow-sm shadow-black/50"
      :class="{ 'p-2': !condensed }"
    >
      <div class="space-y-2" :class="{ 'animate-pulse': !true }">
        <div class="flex items-center justify-center">
          <div
            v-if="item"
            class="relative aspect-video w-full bg-dark"
            :class="{
              'overflow-hidden rounded-t-lg': condensed,
              'rounded-lg': !condensed,
              '!rounded-lg': hideInfo,
            }"
          >
            <NuxtImg
              :src="item.preview.thumbnail"
              class="h-full w-full object-cover"
            />

            <template v-if="item.preview.video && showVideo">
              <video
                class="absolute inset-0 h-full w-full object-cover"
                :src="item.preview.video"
                muted
                loop
                autoplay
                :poster="item.preview.thumbnail"
              />
            </template>

            <IconUltra
              v-if="!item.is_free"
              class="absolute right-0 top-0 m-1 h-6 w-6 rounded-full bg-dark/70 p-1 shadow"
            />
          </div>

          <div v-else class="relative aspect-video h-full w-full">
            &nbsp;
            <UiSkeleton
              class="absolute inset-0 rounded-none bg-black/30"
              :class="{
                '!rounded-t-lg': condensed,
                '!rounded-lg': !condensed,
              }"
            />
          </div>
        </div>
        <div
          v-if="!hideInfo"
          class="m-1"
          :class="{ 'px-2 pb-2': condensed, 'pb-1': !condensed }"
        >
          <div class="relative truncate text-white">
            <template v-if="item">{{ item.name }}</template>
            <div v-else class="relative">
              &nbsp;
              <UiSkeleton class="absolute inset-0.5 bg-black/30" />
            </div>
          </div>
          <div class="truncate text-neutral-400">
            <template v-if="item"
              >{{ item?.creator?.name || "&nbsp;" }}
            </template>
            <div v-else class="relative">
              &nbsp;
              <UiSkeleton class="absolute inset-0.5 bg-black/30" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { useMouseInElement, watchDebounced } from "@vueuse/core";
import { getRouteToItem } from "~/lib/utils";

defineProps<{ item?: LibraryItem; hideInfo?: boolean }>();

const { public: $env } = useRuntimeConfig();
const condensed = computed(() => $env.CONDENSED_LIBRARY_ITEMS);

const el = ref<HTMLDivElement>();

const { isOutside } = useMouseInElement(el);

const showVideo = ref(false);

watch(isOutside, () => {
  if (isOutside.value) {
    showVideo.value = false;
  }
});

watchDebounced(
  isOutside,
  () => {
    if (!isOutside.value) {
      showVideo.value = true;
    }
  },
  {
    debounce: 250,
  },
);
</script>
